import React, { memo, useEffect, useRef, useState } from 'react'

import styles from './coverage.module.scss'

const RenderNavigation = props => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { scrollToSource, sourcesInView, partners, coverageScrollPos, coverageHoverSource } = props
  const [scrollCoverage, setScrollCoverage] = useState<number>(0)

  const navigationPartners = partners && partners.map(item => item.title)

  const handleNavigationToLeft = () => {
    if (sourcesInView) {
      const currentSourceInViewIndex = navigationPartners.indexOf(sourcesInView[0])
      let targetSourceIndex = currentSourceInViewIndex - 3
      if (targetSourceIndex < 0) {
        targetSourceIndex = 0
      }
      const targetSource = navigationPartners[targetSourceIndex]

      scrollToSource(targetSource)
    }
  }

  const handleNavigationToRight = () => {
    if (sourcesInView) {
      const sourcesInViewLastIndex = sourcesInView.length - 1
      const navigationSourceLastIndex = navigationPartners.length - 1
      const currentSourceInViewIndex = navigationPartners.indexOf(sourcesInView[sourcesInViewLastIndex])
      let targetSourceIndex = currentSourceInViewIndex + 1
      if (targetSourceIndex > navigationSourceLastIndex) {
        targetSourceIndex = navigationSourceLastIndex
      }
      const targetSource = navigationPartners[targetSourceIndex]
      scrollToSource(targetSource)
    }
  }

  useEffect(() => {
    let scrollDir: 'forward' | 'back' = 'forward'

    if (coverageScrollPos) {
      setScrollCoverage(coverageScrollPos)
    }

    if (scrollCoverage < coverageScrollPos) {
      scrollDir = 'forward'
    } else {
      scrollDir = 'back'
    }

    if (scrollCoverage && scrollDir === 'forward') {
      ref.current?.scrollBy(coverageScrollPos / 550, 0)
    }
    if (scrollCoverage && scrollDir === 'back') {
      ref.current?.scrollBy(-(coverageScrollPos / 550), 0)
    }
  }, [coverageScrollPos])

  return (
    <div className={styles.coverageHeader}>
      <div
        className={styles.coverageHeaderArrowLeft}
        onClick={handleNavigationToLeft}
        data-disabled={sourcesInView?.length && sourcesInView[0] === navigationPartners[0]}
      >
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.9193 12.629L14.7341 10.4438L8.17739 17.0004L14.7341 23.5571L16.9193 21.3709L14.0893 18.5459L25.7422 18.5459L25.7422 15.455L14.0893 15.455L16.9193 12.629Z"
            fill="#140B26"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.97924 4.97912C2.60173 7.35661 0.982615 10.3857 0.326654 13.6834C-0.32931 16.9811 0.00734067 20.3992 1.29402 23.5056C2.58071 26.6119 4.75964 29.267 7.55528 31.135C10.3509 33.003 13.6377 34 17 34C20.3623 34 23.6491 33.003 26.4447 31.135C29.2403 29.267 31.4193 26.6119 32.706 23.5056C33.9926 20.3992 34.3293 16.9811 33.6733 13.6834C33.0174 10.3857 31.3983 7.35661 29.0208 4.97912C25.8326 1.79104 21.5086 -1.09203e-06 17 -1.48619e-06C12.4913 -1.88035e-06 8.16735 1.79104 4.97924 4.97912ZM7.16447 7.16435C5.21919 9.10955 3.89443 11.5879 3.3577 14.286C2.82097 16.9841 3.09639 19.7808 4.14912 22.3224C5.20185 24.864 6.98461 27.0363 9.27196 28.5647C11.5593 30.0931 14.2485 30.9088 16.9995 30.9088C19.7505 30.9088 22.4397 30.0931 24.727 28.5647C27.0144 27.0363 28.7971 24.864 29.8499 22.3224C30.9026 19.7808 31.178 16.9841 30.6413 14.286C30.1046 11.5879 28.7798 9.10955 26.8345 7.16435C24.2261 4.556 20.6883 3.09066 16.9995 3.09066C13.3107 3.09066 9.77291 4.556 7.16447 7.16435Z"
            fill="#FFC469"
          />
        </svg>
      </div>

      <div ref={ref} className={styles.coverageHeaderList}>
        {partners &&
          partners?.map((item, index) => {
            if (item.news.length > 0) {
              // const isSourceInView = !!sourcesInView.find(source => item.title === source)
              const activeSource = item.title === coverageHoverSource
              return (
                <div
                  key={item.title}
                  className={styles.coverageHeaderItem}
                  id={item.title}
                  data-selected={activeSource}
                  onClick={() => scrollToSource(item.title)}
                >
                  {item.title}
                </div>
              )
            }
          })}
      </div>

      <div
        className={styles.coverageHeaderArrowRight}
        onClick={handleNavigationToRight}
        data-disabled={
          sourcesInView &&
          sourcesInView[sourcesInView.length - 1] === navigationPartners?.[navigationPartners?.length - 1]
        }
      >
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.0807 21.371L19.2659 23.5562L25.8226 16.9996L19.2659 10.4429L17.0807 12.6291L19.9107 15.4541L8.25781 15.4541L8.25781 18.545H19.9107L17.0807 21.371Z"
            fill="#140B26"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0208 29.0209C31.3983 26.6434 33.0174 23.6143 33.6733 20.3166C34.3293 17.0189 33.9927 13.6008 32.706 10.4944C31.4193 7.38807 29.2404 4.73302 26.4447 2.86503C23.6491 0.997037 20.3623 0 17 0C13.6377 0 10.3509 0.997037 7.5553 2.86503C4.75966 4.73302 2.58072 7.38807 1.29404 10.4944C0.007355 13.6008 -0.329292 17.0189 0.32667 20.3166C0.982632 23.6143 2.60174 26.6434 4.97925 29.0209C8.16737 32.209 12.4914 34 17 34C21.5087 34 25.8327 32.209 29.0208 29.0209ZM26.8355 26.8356C28.7808 24.8904 30.1056 22.4121 30.6423 19.714C31.179 17.0159 30.9036 14.2192 29.8509 11.6776C28.7982 9.13601 27.0154 6.96367 24.728 5.4353C22.4407 3.90692 19.7515 3.09116 17.0005 3.09116C14.2495 3.09116 11.5603 3.90692 9.27298 5.4353C6.98563 6.96367 5.20286 9.13601 4.15013 11.6776C3.0974 14.2192 2.82199 17.0159 3.35871 19.714C3.89544 22.4121 5.22021 24.8904 7.16548 26.8356C9.77392 29.444 13.3117 30.9093 17.0005 30.9093C20.6893 30.9093 24.2271 29.444 26.8355 26.8356Z"
            fill="#FFC469"
          />
        </svg>
      </div>
    </div>
  )
}

export default memo(RenderNavigation)
